<template>
  <a-modal title="更多" centered :visible="visible" :closable="false" @ok="handleOk" @cancel="handleCancel">
    <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
      <a-form-item label="企业ID">
        {{ mess.corpId || '/' }}
      </a-form-item>
      <a-form-item label="永久授权码">
        {{ mess.permanentCode || '/' }}
      </a-form-item>
      <a-form-item label="最近一次卸载时间">
        {{ mess.deleteTime || '/' }}
      </a-form-item>
      <a-form-item label="开通时间">
        {{ mess.openTime || '/' }}
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      mess: {},
    }
  },
  methods: {
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    open(mess) {
      this.mess = mess
      this.visible = true
    },
  },
}
</script>
